<template>
  <div
    id="search-field"
    class="d-flex flex-grow-1 search-field mx-5"
  >
    <responsive-menu
      data-cy="search-suggestions"
      :click-outside-args="clickOutsideArgs"
      nudge-bottom=4
      v-model="menuIsOpen"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          data-cy="search-field"
          class="border-radius"
          ref="search-field"
          v-model="GlobalSearch.text"
          @blur="textFieldFocused = false"
          @focus="textFieldFocused = true"
          @keydown="handleKeyDown($event, on)"
          @input="checkMenuOpeningConditions()"
          @click:clear="clearField()"
          :placeholder="computedPlaceholder"
          :prepend-inner-icon="$icon('i.Search')"
          clearable
          dense
          flat
          hide-details
          maxlength=2000
          solo
        >
          <template slot="prepend-inner">
            <v-btn
              data-cy="go-to-search"
              :disabled="!GlobalSearch.allowNavigation"
              :class="{ 'primary--text': textFieldFocused }"
              icon
              height=44
              width=44
              @click.stop="navigateToSearch()"
              @mouseup.stop
            >
              <icon-tooltip
                bottom
                :disabled="!GlobalSearch.allowNavigation"
                :class-icon="{ 'primary--text': textFieldFocused }"
                icon-name="i.Search"
                :tooltip-text="$t('t.ListMode')"
              />
            </v-btn>
          </template>
          <template slot="append">
            <search-local-global
              v-if="GlobalSearch.allowScopedSearch"
              data-cy="search-scope"
              ref="scope-switch"
              v-model="GlobalSearch.scopeMode"
              @input="changeScopeMode"
            />
          </template>
        </v-text-field>
      </template>
      <suggestion-list
        :items="items"
        :loading="loading && !items.length"
        show-icon
        show-detail
        mixed
        @click:item="navigateItem($event.item)"
        :can-edit-column="false"
        ref="suggestList"
      />
      <v-divider v-if="items.length" />
      <v-list-item
        class="more-items flex-grow-0"
        v-if="items.length"
        @click="navigateToSearch"
      >
        <v-list-item-content>
          {{$t('t.ViewDetails')}}
        </v-list-item-content>
      </v-list-item>
    </responsive-menu>
  </div>
</template>

<script>
import Search, { defaultSearch, GlobalSearch } from '../controllers'
import navigation from '@/navigationController'
import recentsController from '@/recentsController'

export default {
  components: {
    ResponsiveMenu: () => import('@/pages/search/components/responsive-menu'),
    SearchLocalGlobal: () => import('./search-local-global'),
    SuggestionList: () => import('@/pages/search/components/suggestion-list'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  computed: {
    clickOutsideArgs () {
      return {
        handler: () => { this.menuIsOpen = false },
        include: () => [
          this.$refs?.['search-field']?.$el,
          this.$refs?.['scope-switch']?.$el
        ].filter(e => e)
      }
    },
    computedPlaceholder () {
      return this.placeholder ?? (GlobalSearch.allowScopedSearch ? this.$t('t.SearchIn') : this.$t('t.Search'))
    }
  },
  data () {
    return {
      GlobalSearch,
      items: [],
      loading: false,
      menuIsOpen: false,
      newSearch: true,
      search: defaultSearch.chain(s => s.searchedDocumentTypes.exclude([
        'aging-buckets',
        'announcement-origins',
        'announcement-statuses',
        'ar24-message-statuses',
        'ar24-messages',
        'auto-filters',
        'calendar-based-reminder-protocols',
        'calendar-based-reminder-statuses',
        'collaboration-task-types',
        'collection-segments',
        'column-sets',
        'contact-groups',
        'credit-hold-reasons',
        'credit-safes',
        'credit-status-groupamas',
        'credit-statuses',
        'cultures',
        'currencies',
        'dispute-reason-categories',
        'dispute-reasons',
        'document-types',
        'dunning-reminder-statuses',
        'dunning-runs',
        'escalation-protocol-levels',
        'fields',
        'group-contributors',
        'groupama-decision-codes',
        'groups',
        'import-data-sources',
        'imports',
        'invoice-statuses',
        'maileva-letters',
        'maileva-letter-statuses',
        'main-presets',
        'message-priorities',
        'payments',
        'portal-payment-statuses',
        'portal-statuses',
        'promise-statuses',
        'provider-types',
        'relative-currencies',
        'reminder-runs',
        'reminder-types',
        'risk-categories',
        'roles',
        'run-statuses',
        'run-types',
        'runs',
        'sort-sets',
        'status-disputes',
        'summaries',
        'templates',
        'template-types',
        'transaction-types',
        'unapplied-cashes',
        'work-in-progress-items',
        'work-item-priorities',
        'work-item-types',
        'workflows'
      ])),
      textFieldFocused: false
    }
  },
  methods: {
    changeScopeMode () {
      this.prepareNewSearch()
      this.checkMenuOpeningConditions()
    },
    checkMenuOpeningConditions () {
      this.$refs['search-field'].focus()
      this.$triggerResize()
    },
    // Disabled right now
    checkRetrieveNextPage (itemCount) {
      if (itemCount && itemCount % this.search.pageSize === 0) {
        this.search.retrieveNextPage()
      }
    },
    clearField () {
      this.menuIsOpen = false
      this.items = []
      if (GlobalSearch.activeSearch.action === Search.actions.GRID) {
        GlobalSearch.activeSearch.execute(true)
      }
    },
    executeSearchIfNeeded () {
      if (this.menuIsOpen) {
        this.search.execute()
      }
    },
    findHighlightedItem () {
      // since we can highlight an item by the keyboard and vue does not have this prop
      // we find this item by searching for it the the dom.
      const it = this.$refs?.suggestList?.$el?.querySelector('.v-list-item--highlighted')?.parentElement

      if (it) {
        const children = it.parentElement.children

        // find the index of the element inside the dom and use that index
        // to find the item in the inner items list.
        for (let i = 0; i < children.length; i++) {
          if (children.item(i) === it) {
            return this.items[i]
          }
        }
      }
    },
    handleKeyDown (event, on) {
      const kc = event.keyCode
      if (kc === 38 || kc === 40) {
        on.keydown(event)
      } else if (kc === 13) {
        const item = this.findHighlightedItem()
        if (item) {
          this.navigateItem(item)
          event?.target?.blur()
        } else {
          this.prepareNewSearch()
          GlobalSearch.activeSearch.execute(true)
          if (GlobalSearch.activeSearch === this.search) {
            this.menuIsOpen = true
          }
        }
      }
    },
    async navigateItem (item) {
      await navigation.navigateTo(item.id, item.type)
    },
    navigateToSearch () {
      if (this.search.searchText || this.$route.name === 'Dashboard') {
        GlobalSearch.propagateActualSearchText()
      }
      if (
        this.search.searchText &&
        GlobalSearch.allowScopedSearch &&
        GlobalSearch.scopeMode === GlobalSearch.LOCAL_MODE
      ) {
        recentsController.goToStaticTab(
          {
            documentId: this.$route.params.id,
            documentType: 'accounts'
          },
          'documents')
      } else {
        this.$router.push('/search').catch(_ => { })
      }
    },
    onMenuClose () {
      if (GlobalSearch.activeSearch.action === Search.actions.SUGGEST) {
        this.search.clearText().clearItems()
      }
    },
    prepareNewSearch () {
      this.newSearch = true
      this.search.setPage(1)
    },
    setLoadingOff () { this.loading = false },
    setLoadingOn () { this.loading = true }
  },
  mounted () {
    GlobalSearch.onScopeChange(this.executeSearchIfNeeded)
    this.search.onQuerying(this.setLoadingOn)
    this.search.onComplete(this.setLoadingOff)
  },
  beforeDestroy () {
    GlobalSearch.offScopeChange(this.executeSearchIfNeeded)
    this.search.offQuerying(this.setLoadingOn)
    this.search.offComplete(this.setLoadingOff)
  },
  watch: {
    // This watcher fix a rendering issue when propagating a text to a new search
    'GlobalSearch.text' () {
      this.$nextTick(this.$forceUpdate)
    },
    menuIsOpen (isOpen) {
      if (!isOpen) {
        this.onMenuClose()
      }
    },
    'search.searchText' () {
      this.prepareNewSearch()
    },
    'search.items' (items) {
      if (this.newSearch) {
        this.items = items
      } else {
        this.items.push(...items)
      }
      this.newSearch = false
    },
    $route () {
      this.menuIsOpen = false
    }
  },
  props: {
    placeholder: String
  }
}
</script>

<style lang="stylus" scoped>
.search-field
  max-width 750px

.more-items
  flex-basis auto !important
</style>
